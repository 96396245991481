const production = {
  REACT_APP_BASE_URL: 'https://api.mangangaprodutos.com.br/api',
  REACT_APP_URL: 'https://app.mangangaprodutos.com.br',
  REACT_APP_BASENAME: null,
};

// const production = {
//   REACT_APP_BASE_URL: 'https://manganga-api.sandbox.interativa.digital/api',
//   REACT_APP_URL: 'https://manganga-painel.sandbox.interativa.digital',
//   REACT_APP_BASENAME: null,
// };

const dev = {
  REACT_APP_BASE_URL: 'https://manganga-api.sandbox.interativa.digital/api',
  REACT_APP_URL: 'http://localhost:3000',
  REACT_APP_BASENAME: null,
};

const config = {
  production,
  development: dev,
};

export default {
  REACT_APP_VERSION: '1.0.4',
  REACT_APP_NAME: 'Mangangá',
  REACT_APP_FULL_NAME: 'O PURO SABOR DO MEL',
  REACT_APP_ADDRESS: '',
  ...config[process.env.NODE_ENV || 'development'],
};
