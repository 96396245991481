import React from 'react';

import { Button, ButtonProps, makeStyles } from '@material-ui/core';

interface CustomButtonProps extends ButtonProps {
  children: React.ReactNode;
}

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #f39c12 30%, #efcc6a 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 170, 105, .3)',
    color: 'white',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
});

export function CustomButton({ children, ...rest }: CustomButtonProps) {
  const classes = useStyles();

  return (
    <Button {...rest} className={classes.root}>
      {children}
    </Button>
  );
}
