import React from 'react';

import { Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    '& .MuiPaper-root': {
      background: '#F8F0D7',
    },
  },
  color: {
    '& .MuiListItemText-primary': {
      color: theme.palette.primary.main,
    },
  },
}));

type Item = {
  id: number;
  name: string;
};

interface MenuDialogMeliponarioProps {
  open: boolean;
  onClose: (value?: Item) => void;
  data: Item[];
}

export function MenuDialogMeliponario({
  onClose,
  open,
  data,
}: MenuDialogMeliponarioProps) {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = ({ id, name }: Item) => {
    onClose({ id, name });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-meliponario"
      open={open}
      className={classes.background}
    >
      <DialogTitle id="simple-dialog-meliponario">
        Selecionar Meliponário
      </DialogTitle>
      <Divider />
      <List>
        {data.map((item) => (
          <ListItem
            button
            onClick={() => handleListItemClick(item)}
            key={item.id}
          >
            <ListItemText primary={item.name} className={classes.color} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
