import React from 'react';

import { Box, Drawer } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import logoImg from '../../../assets/logo.png';
import bgImg from '../../../assets/manganga-bg-header.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      overflowY: 'auto',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      width: '240px',
      maxWidth: '240px',
      background: theme.palette.info.main,
      backgroundImage: `linear-gradient(#f8f0d7f0, #f8f0d7f0), url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'left',
      backgroundRepeat: 'no-repeat',
    },
    image: {
      objectFit: 'contain',
      objectPosition: 'center',
      width: '200px',
      margin: theme.spacing(1, 'auto', 4, 'auto'),
    },
  }),
);

interface MenuMobileProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export function MenuMobile({ open, onClose, children }: MenuMobileProps) {
  const classes = useStyles();
  return (
    <Drawer anchor="left" open={open} onClose={() => onClose()}>
      <Box className={classes.root}>
        <img src={logoImg} alt="Logo" className={classes.image} />
        {children}
      </Box>
    </Drawer>
  );
}
