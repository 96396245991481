import React, { FC } from 'react';

import { Box } from '@material-ui/core';

import { useStyles } from './styles';

const Clean: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="20px"
        width="100%"
        maxWidth="400px"
        margin="0 auto"
      >
        {children}
      </Box>
    </Box>
  );
};

export default Clean;
