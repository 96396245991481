import React, { createContext, useCallback, useState, useContext } from 'react';

import axios from 'axios';
import { isBefore } from 'date-fns';

interface IUser {
  id: string;
  name: string;
  email?: string;
  created_at: string;
}

interface AuthState {
  token: string | null;
  user: IUser;
}

interface ISignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: IUser;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(updateData: Partial<IUser>): void;
  refresh(): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@app:token');
    const expires_token = localStorage.getItem('@app:expires_token');
    const user = localStorage.getItem('@app:user');

    // return mock;

    if (!expires_token) {
      return {} as AuthState;
    }

    const unexpiredToken = isBefore(
      new Date(),
      new Date(JSON.parse(expires_token)),
    );

    if (token && user && unexpiredToken) {
      axios.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    if (!unexpiredToken) {
      localStorage.removeItem('@app:token');
      localStorage.removeItem('@app:expires_token');
      localStorage.removeItem('@app:user');
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await axios.post('/signin', {
      email,
      password,
      app: 1,
    });

    const { access_token: token, expires_in, user } = response.data.data;

    const datetime = new Date();
    const expires_token = new Date(
      datetime.setSeconds(Number(expires_in) - 10),
    );

    localStorage.setItem('@app:token', token);
    localStorage.setItem('@app:expires_token', JSON.stringify(expires_token));
    localStorage.setItem('@app:user', JSON.stringify(user));

    axios.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const refresh = useCallback(async () => {
    const response = await axios.post('/refresh');

    const { access_token: token, expires_in, user } = response.data.data;

    const datetime = new Date();
    const expires_token = new Date(
      datetime.setSeconds(Number(expires_in) - 10),
    );

    localStorage.setItem('@app:token', token);
    localStorage.setItem('@app:expires_token', JSON.stringify(expires_token));
    localStorage.setItem('@app:user', JSON.stringify(user));

    axios.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@app:token');
    localStorage.removeItem('@app:user');
    localStorage.removeItem('@app:expires_token');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (updateData: IUser) => {
      const userUpdate = {
        ...data.user,
        ...updateData,
      };

      localStorage.setItem('@app:user', JSON.stringify(userUpdate));

      setData({
        token: data.token,
        user: userUpdate,
      });
    },
    [setData, data],
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser, refresh }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
