import React from 'react';

import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';

interface MenuItemSignOutProps {
  signOut: () => void;
}

export function MenuItemSignOut({ signOut }: MenuItemSignOutProps) {
  return (
    <List>
      <ListItem button onClick={() => signOut()}>
        <ListItemIcon>
          <PowerIcon color="inherit" />
        </ListItemIcon>
        <ListItemText primary="Sair" />
      </ListItem>
    </List>
  );
}
