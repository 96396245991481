import { SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import AssignmentIcon from '@material-ui/icons/AssignmentOutlined';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import EmojiNatureIcon from '@material-ui/icons/EmojiNatureOutlined';
import FolderOpenIcon from '@material-ui/icons/FolderOpenOutlined';
import ForumIcon from '@material-ui/icons/ForumOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';

type Menu = {
  title: string;
  url: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
};

const primaryMenu: Menu[] = [
  {
    title: 'Início',
    Icon: DashboardIcon,
    url: '/home',
  },
  {
    title: 'Meliponários',
    Icon: FolderOpenIcon,
    url: '/meliponarios',
  },
  {
    title: 'Colônias',
    Icon: EmojiNatureIcon,
    url: '/colonies',
  },
  {
    title: 'Suporte Técnico',
    Icon: AssignmentIcon,
    url: '/technical-supports',
  },
  {
    title: 'Mensagens',
    Icon: ForumIcon,
    url: '/messages',
  },
  {
    title: 'Como Funciona',
    Icon: HelpOutlineIcon,
    url: '/how-it-works',
  },
];

const secondMenu: Menu[] = [
  {
    title: 'Perfil',
    Icon: PersonIcon,
    url: '/profile',
  },
  {
    title: 'Suporte',
    Icon: InfoIcon,
    url: '/support',
  },
];

const hiddenMenu: Menu[] = [
  {
    title: 'Manejos',
    Icon: PersonIcon,
    url: '/home/managements',
  },
  {
    title: 'População',
    Icon: PersonIcon,
    url: '/home/population',
  },
  {
    title: 'Provisão',
    Icon: PersonIcon,
    url: '/home/provisions',
  },
  {
    title: 'Arraçoamento',
    Icon: PersonIcon,
    url: '/home/arracoamentos',
  },
  {
    title: 'Biometria',
    Icon: PersonIcon,
    url: '/home/biometries',
  },
];

const fullMenu: Menu[] = [...hiddenMenu, ...primaryMenu, ...secondMenu];

const menu = {
  getPrimaryMenus: () => {
    return primaryMenu;
  },
  getSecondMenus: () => {
    return secondMenu;
  },
  getSelected: (pathname: string) => {
    return fullMenu.find((item) => pathname.includes(item.url));
  },
};

export default menu;
