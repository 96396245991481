import React from 'react';

import {
  AppBar,
  Box,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles(() =>
  createStyles({
    bgcolor: {
      maxWidth: '100%',
      '& .MuiDialog-paperFullScreen': {
        background: '#fbf3db',
      },
    },
  }),
);

interface SimpleModalToRegisterProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  children: React.ReactNode;
}

export const SimpleModalToRegister: React.FC<SimpleModalToRegisterProps> = ({
  open,
  onClose,
  title = 'Cadastrar',
  description,
  children,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      className={classes.bgcolor}
    >
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
            autoFocus
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" style={{ marginLeft: 8, flex: 1 }}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>

      <Box display="flex" flexDirection="column" padding={2} width="100%">
        <Typography component="div" color="secondary">
          <Box fontSize={14} textAlign="center" mb={2}>
            {description}
          </Box>
        </Typography>

        {children}
      </Box>
    </Dialog>
  );
};
