import React, { FC, lazy, Suspense } from 'react';
import { Switch } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';

import AuthenticationLayout from '../layouts/Authentication';
import CleanLayout from '../layouts/Clean';
import DefaultLayout from '../layouts/Default';
import Register from '../pages/auth/Register';
import { NewColony } from '../pages/protected/Home/NewColony';
import Route from './Route';

const Home = lazy(() => import('../pages/protected/Home'));
const Population = lazy(() => import('../pages/protected/Home/Population'));
const Provision = lazy(() => import('../pages/protected/Home/Provision'));
const Arracoamento = lazy(() => import('../pages/protected/Home/Arracoamento'));
const Biometry = lazy(() => import('../pages/protected/Home/Biometry'));
const Colony = lazy(() => import('../pages/protected/Colony'));
const Meliponario = lazy(() => import('../pages/protected/Meliponario'));
const Management = lazy(() => import('../pages/protected/Management'));
const Messages = lazy(() => import('../pages/protected/Messages'));
const HowItWorks = lazy(() => import('../pages/protected/HowItWorks'));
const Support = lazy(() => import('../pages/protected/Support'));
const TechnicalSupport = lazy(
  () => import('../pages/protected/TechnicalSupport'),
);
const Profile = lazy(() => import('../pages/protected/Profile'));

const SignIn = lazy(() => import('../pages/auth/SignIn'));

const ForgotPassword = lazy(() => import('../pages/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/auth/ResetPassword'));

const Page404 = lazy(() => import('../pages/404'));

const AppNavigation: FC = () => {
  return (
    <>
      <Suspense fallback={<LinearProgress color="primary" />}>
        <Switch>
          <Route
            path="/"
            exact
            component={SignIn}
            template={AuthenticationLayout}
          />
          <Route
            path="/login"
            exact
            component={SignIn}
            template={AuthenticationLayout}
          />

          <Route
            path="/register"
            exact
            component={Register}
            template={CleanLayout}
          />
          <Route
            path="/forgot-password"
            exact
            component={ForgotPassword}
            template={AuthenticationLayout}
          />
          <Route
            path="/reset-password/:token"
            exact
            component={ResetPassword}
            template={AuthenticationLayout}
          />

          <Route
            path="/home/managements/:colonyId"
            exact
            isPrivate
            component={Management}
            template={DefaultLayout}
          />
          <Route
            path="/home/:colonyId?"
            exact
            isPrivate
            component={Home}
            template={DefaultLayout}
          />
          <Route
            path="/home/populations/:colonyId"
            exact
            isPrivate
            component={Population}
            template={DefaultLayout}
          />
          <Route
            path="/home/provisions/:colonyId"
            exact
            isPrivate
            component={Provision}
            template={DefaultLayout}
          />
          <Route
            path="/home/arracoamentos/:colonyId"
            exact
            isPrivate
            component={Arracoamento}
            template={DefaultLayout}
          />
          <Route
            path="/home/biometries/:colonyId"
            exact
            isPrivate
            component={Biometry}
            template={DefaultLayout}
          />
          <Route
            path="/home/new-colony/:colonyId/:meliponarioId"
            exact
            isPrivate
            component={NewColony}
            template={DefaultLayout}
          />
          <Route
            path="/colonies"
            exact
            isPrivate
            component={Colony}
            template={DefaultLayout}
          />
          <Route
            path="/meliponarios"
            exact
            isPrivate
            component={Meliponario}
            template={DefaultLayout}
          />
          <Route
            path="/messages"
            exact
            isPrivate
            component={Messages}
            template={DefaultLayout}
          />
          <Route
            path="/technical-supports"
            exact
            isPrivate
            component={TechnicalSupport}
            template={DefaultLayout}
          />
          <Route
            path="/how-it-works"
            exact
            isPrivate
            component={HowItWorks}
            template={DefaultLayout}
          />
          <Route
            path="/support"
            exact
            isPrivate
            component={Support}
            template={DefaultLayout}
          />

          <Route
            path="/profile"
            exact
            isPrivate
            component={Profile}
            template={DefaultLayout}
          />

          {/* 404 */}
          <Route
            path="/*"
            isPublic
            component={Page404}
            template={AuthenticationLayout}
          />
        </Switch>
      </Suspense>
    </>
  );
};

export default AppNavigation;
