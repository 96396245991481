import React from 'react';

import { createStyles, Fab, makeStyles, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'absolute',
      bottom: theme.spacing(1),
      right: theme.spacing(1),
      transition: 'all 0.2s',
      [theme.breakpoints.up('sm')]: {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
      },
    },
  }),
);

interface ButtonAddProps {
  onClick: () => void;
}

export function ButtonAdd({ onClick }: ButtonAddProps) {
  const classes = useStyles();

  return (
    <Fab
      color="primary"
      aria-label="add"
      className={classes.fab}
      onClick={() => onClick()}
    >
      <AddIcon />
    </Fab>
  );
}
