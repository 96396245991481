import React from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';

import abelhasImg from '../../assets/cera-de-abelha.svg';

const useStyles = makeStyles({
  root: {
    opacity: 0.6,
    padding: 20,
    textAlign: 'center',
  },
  title: {
    fontSize: 18,
  },
  image: {
    width: '100%',
    maxWidth: 200,
    height: 200,
    backgroundImage: `url(${abelhasImg})`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
});

interface NoDataToDisplayProps {
  title?: string;
}

export const NoDataToDisplay: React.FC<NoDataToDisplayProps> = ({
  title,
  children,
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <div className={classes.image} />
      {title && (
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.title}
        >
          {title}
        </Typography>
      )}
      {children}
    </Box>
  );
};
