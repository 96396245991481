/* eslint-disable prettier/prettier */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { CssBaseline } from '@material-ui/core';

import Boot from './boot';
import config from './config/environmentConfig';
import AppProvider from './hooks';
import AppNavigation from './routes/AppNavigation';

const App: React.FC = () => {
  Boot().then(() => { });
  const basename = config.getEnv('REACT_APP_BASENAME') || '/';

  return (
    <AppProvider>
      <BrowserRouter basename={basename}>
        <CssBaseline />
        <AppNavigation />
      </BrowserRouter>
    </AppProvider>
  );
};

export default App;
