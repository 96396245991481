import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    height: 42,
    overflow: 'hidden',
    position: 'relative',
    display: 'block',
  },
  box: {
    position: 'absolute',
    height: '100%',
    top: 0,
    width: '100%',
    display: 'block',
  },
  bg: {
    backgroundImage: 'linear-gradient(#f39c12, #ffffff00, #f39c12)',
  },
  bgDisabled: {
    backgroundImage: 'linear-gradient(#d5cfbf, #ffffff00, #d5cfbf)',
  },
  image: {
    animation: '$mui-ripple-enter 1s infinite',
    width: 40,
    height: '100%',
    objectFit: 'contain',
  },
  '@keyframes mui-ripple-enter': {
    '0%': {
      transform: 'translateY(45px)',
    },
    '100%': {
      transform: 'translateY(-41px)',
    },
  },
}));
