import React from 'react';

import { Box } from '@material-ui/core';

import beeImg from '../../assets/abelhas.svg';
import { useStyles } from './styles';

export const LoadingBee: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img src={beeImg} alt="Bee" className={classes.image} />
    </Box>
  );
};
