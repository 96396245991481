import { makeStyles, Theme } from '@material-ui/core';

import bgBolinhasImg from '../../assets/bolinhas.png';
import bgImg from '../../assets/manganga-bg-header.jpg';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    minHeight: '100vh',
    width: '100%',
    background: '#f2ecd9',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: '90px',
    maxWidth: '90%',
    objectFit: 'contain',
    objectPosition: 'center',
    margin: '0 auto',
    zIndex: 2,
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    textAlign: 'center',
    background: '#F39C12',
    minHeight: 350,
    [theme.breakpoints.down('sm')]: {
      minHeight: 260,
    },
  },
  bgHeader: {
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${bgImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top left',
    backgroundRepeat: 'no-repeat',
    opacity: 0.2,
    zIndex: 1,
  },
  bgHeaderBolinhas: {
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${bgBolinhasImg})`,
    backgroundSize: 'container',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    zIndex: 3,
  },
}));
