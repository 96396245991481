import React, { createContext, useContext, useCallback } from 'react';

import { useSnackbar } from 'notistack';

interface IToastMessage {
  variant?: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined;
  title: string;
  description?: string;
}

interface ISnackbarsContextData {
  addSnackbar(message: Omit<IToastMessage, 'id'>): void;
}

const SnackbarsContext = createContext<ISnackbarsContextData>(
  {} as ISnackbarsContextData,
);

const SnackbarCustomProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const addSnackbar = useCallback(
    ({ title, variant }: IToastMessage) => {
      enqueueSnackbar(title, {
        variant,
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      });
      // closeSnackbar()
    },
    [enqueueSnackbar],
  );

  return (
    <SnackbarsContext.Provider value={{ addSnackbar }}>
      {children}
    </SnackbarsContext.Provider>
  );
};

function useCustomSnackbar(): ISnackbarsContextData {
  const context = useContext(SnackbarsContext);

  if (!context) {
    throw new Error('useToast must be used within a SnackbarCustomProvider');
  }

  return context;
}

export { SnackbarCustomProvider, useCustomSnackbar };
