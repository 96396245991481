import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Box } from '@material-ui/core';
import axios from 'axios';
import { format } from 'date-fns';

import { ButtonAdd } from '../../../../components/ButtonAdd';
import { DataTable } from '../../../../components/DataTable';
import { useCustomSnackbar } from '../../../../hooks/snackbar';
import { Header } from '../components/Header';
import { CreateNewColony } from './CreateNewColony';
import { Data, QueryParams, ResponseData } from './types';

export function NewColony() {
  const history = useHistory();
  const params = useParams<{ colonyId: string; meliponarioId: string }>();
  const { addSnackbar } = useCustomSnackbar();

  const [open, setOpen] = useState(false);
  const [newColonies, setNewColonies] = useState<Data[]>([]);

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalNewColonies, setTotalNewColonies] = useState(0);
  const [loading, setLoading] = useState(true);

  const requestData = useCallback(
    ({ currentPage, meliponarioId, axiosToken }: QueryParams) => {
      axios
        .get<ResponseData>(
          `/new-colony/${meliponarioId}?page=${currentPage}&limit=10`,
          {
            cancelToken: axiosToken,
          },
        )
        .then((response) => {
          console.log(response.data);
          const { data, last_page, total } = response.data?.data;

          if (currentPage === 1) {
            setNewColonies(
              data.map((item: Data) => {
                return {
                  ...item,
                  formatted_date: format(
                    new Date(item.collection_date),
                    'dd/MM/yyyy',
                  ),
                };
              }),
            );
          } else {
            setNewColonies((state) => [
              ...state,
              ...data.map((item: Data) => {
                return {
                  ...item,
                  formatted_date: format(
                    new Date(item.collection_date),
                    'dd/MM/yyyy',
                  ),
                };
              }),
            ]);
          }

          setLastPage(last_page);
          setTotalNewColonies(total);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);

          if (err?.message === 'canceled') return;

          addSnackbar({
            variant: 'error',
            title:
              err.response?.data?.message ||
              'Erro no servidor! Não foi possível indentificar o error. Entre em contato com o suporte.',
          });
        });
    },
    [addSnackbar],
  );

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    requestData({
      currentPage: 1,
      meliponarioId: params.meliponarioId,
      axiosToken: source.token,
    });

    // axios
    //   .get<{ data: ColonyData }>(`/colonias/${params.colonyId}`, {
    //     cancelToken: source.token,
    //   })
    //   .then((response) => {
    //     setColony(response.data?.data);
    //   })
    //   /* eslint-disable */
    //   .catch(() => { });
    /* eslint-enable */
    return () => {
      source.cancel('canceled');
      setNewColonies([]);
      setPage(1);
      setLastPage(1);
      setLoading(false);
    };
  }, [params.meliponarioId, requestData]);

  const handlePage = useCallback(
    (currentPage: number) => {
      setPage(currentPage);
      requestData({
        currentPage,
        meliponarioId: params.meliponarioId,
      });
    },
    [requestData, params.meliponarioId],
  );

  const handleDelete = useCallback(
    async (id) => {
      if (!id) return;
      setLoading(true);

      axios
        .delete(`/new-colony/${id}`)
        .then(() => {
          addSnackbar({
            title: 'Registro deletado com sucesso!',
          });

          requestData({
            currentPage: 1,
            meliponarioId: params.meliponarioId,
          });
          setPage(1);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          addSnackbar({
            variant: 'error',
            title:
              err?.response?.data?.message ||
              'Erro no servidor! Não foi possível indentificar o error. Entre em contato com o suporte.',
          });
        });
    },
    [addSnackbar, requestData, params.meliponarioId],
  );

  const tableHeadCells = {
    id: 'ID',
    colony_donating_name: 'Colônia Doadora',
    donating_colony_description: 'Descrição Doadora',
    colony_recipient_name: 'Colônia Receptora',
    recipient_colony_description: 'Descrição Receptora',
    formatted_date: 'Data',
  };

  const handleClose = useCallback(() => {
    requestData({
      currentPage: 1,
      meliponarioId: params.meliponarioId,
    });
    setOpen(false);
  }, [requestData, params.meliponarioId]);

  return (
    <>
      <Header
        name="Produção de Novas Colônias"
        description=""
        onPush={() => history.push(`/home/${params.colonyId}`)}
      />

      <Box px={2}>
        <DataTable
          headCells={tableHeadCells}
          dataRows={newColonies}
          dataTotal={totalNewColonies}
          isLoading={loading}
          onDelete={(value) => handleDelete(value?.id)}
          onNextPage={() => handlePage(page + 1)}
          isDisabledNextPage={page === lastPage || loading}
        />
      </Box>

      <ButtonAdd onClick={() => setOpen(true)} />

      <CreateNewColony
        open={open}
        meliponarioId={params.meliponarioId}
        onClose={() => setOpen(false)}
        onSuccess={handleClose}
      />
    </>
  );
}
