import React from 'react';
import { Link } from 'react-router-dom';

import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
} from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

type Menu = {
  title: string;
  url: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
};

interface MenuItemsProps {
  data: Menu[];
  divider: boolean | null;
  onClick: () => void;
}

export function MenuItems({ data, divider = null, onClick }: MenuItemsProps) {
  if (!data.length) return <></>;

  return (
    <>
      <List>
        {data.map((itemMenu) => {
          return (
            <ListItem
              key={itemMenu.url}
              button
              component={Link}
              to={itemMenu.url}
              onClick={() => onClick()}
            >
              <ListItemIcon>
                <itemMenu.Icon color="inherit" />
              </ListItemIcon>
              <ListItemText primary={itemMenu.title} />
            </ListItem>
          );
        })}
      </List>
      {divider && <Divider />}
    </>
  );
}
