import React from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';

interface HeaderProps {
  name: string | undefined;
  description: string | undefined;
  onPush: () => void;
}

export function Header({
  name = undefined,
  description = undefined,
  onPush,
}: HeaderProps) {
  return (
    <Typography component="div">
      <Box padding={2} paddingBottom={0}>
        <Box
          fontSize="18px"
          color="text.secondary"
          mb={1}
          display="flex"
          alignItems="center"
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={onPush}
            aria-label="close"
          >
            <ArrowBack />
          </IconButton>
          <Box ml={1}>{name || <Skeleton variant="text" width={150} />}</Box>
        </Box>
        <Box fontSize="14px" color="secondary.main" textAlign="center">
          {description}
        </Box>
      </Box>
    </Typography>
  );
}
