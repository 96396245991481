import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    appBar: {
      color: theme.palette.text.secondary,
      width: '100%',
      marginLeft: 0,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up('sm')]: {
        marginLeft: '240px',
        width: 'calc(100% - 240px)',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

interface NavBarProps {
  onOpenMenu: () => void;
  menuId: string;
  mobileMenuId: string;
  onProfileMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
  onMobileMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
}

export function NavBar({
  onOpenMenu,
  menuId,
  mobileMenuId,
  onProfileMenuOpen,
  onMobileMenuOpen,
  children,
}: NavBarProps) {
  const classes = useStyles();
  const { push } = useHistory();

  const handleMenuPush = (router: string) => {
    if (router === '/messages') {
      setNotify(false);
      push(router);
      axios.post('/viewed-notifications');
    }
    if (router === '/technical-supports') {
      setSupport(false);
      push(router);
    }
  };

  const [notify, setNotify] = useState(false);
  const [support, setSupport] = useState(false);

  useEffect(() => {
    axios.get('/has-notifications').then((response) => {
      if (response.data.data.new_notification) {
        setNotify(true);
      }
    });
  }, []);

  useEffect(() => {
    axios.get('/technical-supports').then((response) => {
      const suportes = response.data.data.data.map(
        (suporteNote: { updated_at: String }) => ({
          updated_at: suporteNote.updated_at,
        }),
      );
      const firstElement = suportes.shift();
      const secondElement = suportes[0];

      if (firstElement !== secondElement) {
        setSupport(true);
      }
    });
  }, []);

  return (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="open drawer"
          onClick={() => onOpenMenu()}
        >
          <MenuIcon />
        </IconButton>

        {children}

        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          <IconButton
            aria-label="show 4 new mails"
            color="inherit"
            onClick={() => handleMenuPush('/messages')}
          >
            {notify ? (
              <Badge badgeContent="" color="error" variant="dot">
                <MailIcon />
              </Badge>
            ) : (
              <MailIcon />
            )}
          </IconButton>
          <IconButton
            aria-label="show 17 new notifications"
            color="inherit"
            onClick={() => handleMenuPush('/technical-supports')}
          >
            {support ? (
              <Badge badgeContent="" color="error" variant="dot">
                <AssignmentIcon />
              </Badge>
            ) : (
              <AssignmentIcon />
            )}
          </IconButton>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={onProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={onMobileMenuOpen}
            color="inherit"
          >
            <MoreIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}
