import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { format } from 'date-fns';
import * as yup from 'yup';

import { CustomButton } from '../../../../../components/CustomButton';
import { DatePickerHook } from '../../../../../components/DatePickerHook';
import InputHook from '../../../../../components/InputHook';
import { SimpleModalToRegister } from '../../../../../components/Modal/SimpleModalToRegister';
import { useCustomSnackbar } from '../../../../../hooks/snackbar';

const schema = yup.object().shape({
  donating_colony_description: yup.string(),
  recipient_colony_description: yup.string(),
  collection_date: yup.string(),
});

type Colony = {
  id: number;
  name: string;
};

type IFormInput = {
  donating_colony_description: string;
  recipient_colony_description: string;
  collection_date: string;
};

interface CreateNewColonyProps {
  open: boolean;
  meliponarioId: number | string;
  onClose: () => void;
  onSuccess: () => void;
}

export function CreateNewColony({
  open,
  meliponarioId,
  onClose,
  onSuccess,
}: CreateNewColonyProps) {
  const { addSnackbar } = useCustomSnackbar();

  const [loading, setLoading] = useState(false);
  const [donatingColony, setDonatingColony] = useState<Colony | undefined>();
  const [recipientColony, setRecipientColony] = useState<Colony | undefined>();

  const [colonies, setColonies] = useState<Colony[]>([]);

  const {
    control,
    handleSubmit,
    errors,
    reset: resetForm,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      collection_date: format(new Date(), "yyyy-MM-dd'T13:00:00'"),
    },
  });

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    axios
      .get<{ data: Colony[] }>(`/meliponarios/${meliponarioId}/colonias`, {
        cancelToken: source.token,
      })
      .then((response) => {
        const { data } = response.data;

        setColonies(data);

        setLoading(false);
      })
      .catch((err) => {
        if (err?.message === 'canceled') return;
        setLoading(false);
      });

    return () => {
      source.cancel('canceled');
      setColonies([]);
      setLoading(false);
    };
  }, [meliponarioId]);

  const onSubmit = useCallback(
    (values: IFormInput) => {
      console.log(donatingColony?.id);
      if (!donatingColony?.id || !recipientColony?.id) return;
      console.log(values);
      setLoading(true);

      const formatData = {
        collection_date: format(new Date(values.collection_date), 'yyyy-MM-dd'),
        donating_colony_description: values.donating_colony_description,
        recipient_colony_description: values.recipient_colony_description,
        donating_colony_id: donatingColony.id,
        recipient_colony_id: recipientColony.id,
      };

      axios
        .post(`/new-colony/${meliponarioId}`, formatData)
        .then(() => {
          addSnackbar({
            title: 'Dados cadastrados com sucesso!',
          });

          setLoading(false);
          resetForm();
          onSuccess();
        })
        .catch((err) => {
          console.log(err);
          addSnackbar({
            variant: 'error',
            title:
              err?.response?.data?.message ||
              'Erro no servidor! Não foi possível indentificar o error. Entre em contato com o suporte.',
          });

          setLoading(false);
        });
    },
    [
      addSnackbar,
      onSuccess,
      meliponarioId,
      donatingColony?.id,
      recipientColony?.id,
      resetForm,
    ],
  );

  const handleChangeDonating = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    setDonatingColony(colonies.find((item) => item.id === event.target.value));
  };

  const handleChangeRecipient = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    console.log(event.target.value);
    setRecipientColony(colonies.find((item) => item.id === event.target.value));
  };

  return (
    <SimpleModalToRegister
      open={open}
      onClose={onClose}
      title="Produção de Novas Colônias"
      description="Dados referentes a produção de novas Colônias."
    >
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        onSubmit={handleSubmit(onSubmit)}
        width="100%"
        maxWidth="500px"
        mx="auto"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>Colônia Doadora</Typography>
          <Select
            value={donatingColony}
            onChange={handleChangeDonating}
            variant="outlined"
            style={{ minWidth: 200 }}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            required
          >
            {colonies.map((item) => (
              <MenuItem key={`recipient-${item.id}`} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <InputHook
          name="donating_colony_description"
          type="text"
          multiline
          minRows={4}
          control={control}
          error={errors}
          variant="outlined"
          required
          style={{ marginTop: 20, marginBottom: 20 }}
          disabled={loading}
        />

        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>Colônia Receptora</Typography>
          <Select
            value={recipientColony}
            onChange={handleChangeRecipient}
            variant="outlined"
            style={{ minWidth: 200 }}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {colonies.map((item) => (
              <MenuItem key={`recipient-${item.id}`} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <InputHook
          name="recipient_colony_description"
          type="text"
          multiline
          minRows={4}
          control={control}
          error={errors}
          variant="outlined"
          required
          style={{ marginTop: 20, marginBottom: 20 }}
          disabled={loading}
        />

        <DatePickerHook
          name="collection_date"
          label="Data de Coleta"
          variant="outlined"
          control={control}
          error={errors}
          disabled={false}
          style={{ marginBottom: 20 }}
          required
        />

        <CustomButton
          autoFocus
          color="inherit"
          type="submit"
          style={{ height: 56 }}
          disabled={loading}
        >
          {!loading ? 'Salvar' : <CircularProgress size={20} color="inherit" />}
        </CustomButton>
      </Box>
    </SimpleModalToRegister>
  );
}
