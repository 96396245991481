/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { Controller, Control } from 'react-hook-form';

import {
  Box,
  IconButton,
  TextField,
  TextFieldProps,
  Typography,
  makeStyles,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  Error,
  VisibilityOutlined,
  VisibilityOffOutlined,
} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '6px',
    borderColor: '#FFA621',
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      boxShadow: '0px 3px 0px #FFA621',
    },
  },
  inputCenter: {
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
    },
    '& .MuiInputBase-input:focus': {
      boxShadow: '0px 3px 0px #FFA621',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      boxShadow: '0px 3px 0px #FFA621',
    },
  },
}));

type Props = TextFieldProps & {
  control: Control<Record<string, any>>;
  name: string;
  type?: string;
  label?: string;
  error?: any;
  variant: 'outlined' | 'filled' | 'standard';
  style?: React.CSSProperties;
  styleInput?: React.CSSProperties;
  size?: 'medium' | 'small';
  endAdornment?: string;
};

const InputHook: React.FC<Props> = ({
  control,
  name,
  type = 'text',
  label,
  error,
  variant,
  style,
  styleInput,
  size,
  endAdornment,
  ...rest
}) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const isPassword = useMemo(() => type === 'password', [type]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ marginBottom: 15, ...style }}
    >
      <Box display="flex" width="100%" position="relative">
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ onChange, value }) => (
            <TextField
              className={
                styleInput?.textAlign === 'center'
                  ? classes.inputCenter
                  : classes.root
              }
              onChange={onChange}
              value={value}
              type={showPassword ? 'text' : type}
              label={label}
              variant={variant}
              style={{
                marginBottom: 5,
                width: '100%',
                background: '#fff',
                ...styleInput,
              }}
              error={!!error[name]?.message}
              size={size || 'medium'}
              {...rest}
              InputProps={
                endAdornment
                  ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        {endAdornment}
                      </InputAdornment>
                    ),
                  }
                  : {}
              }
            />
          )}
        />

        {isPassword && (
          <IconButton
            onClick={() => setShowPassword((state) => !state)}
            style={{
              position: 'absolute',
              top: size === 'small' ? 8 : 14,
              right: size === 'small' ? 8 : 14,
            }}
            size="small"
          >
            {showPassword ? (
              <VisibilityOffOutlined style={{ width: 20, height: 20 }} />
            ) : (
              <VisibilityOutlined style={{ width: 20, height: 20 }} />
            )}
          </IconButton>
        )}
      </Box>
      {name && error[name]?.message && (
        <Typography
          variant="subtitle2"
          color="error"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Error color="error" style={{ width: 18, marginRight: 4 }} />
          {error[name]?.message}
        </Typography>
      )}
    </Box>
  );
};

export default InputHook;
