import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptLocale from 'date-fns/locale/pt-BR';
import { SnackbarProvider } from 'notistack';

import theme from '../theme';
import { AuthProvider } from './auth';
import { MeliponarioProvider } from './meliponario';
import { SnackbarCustomProvider } from './snackbar';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <SnackbarProvider maxSnack={3}>
          <SnackbarCustomProvider>
            <MeliponarioProvider>{children}</MeliponarioProvider>
          </SnackbarCustomProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </AuthProvider>
);

export default AppProvider;
