/* eslint-disable @typescript-eslint/no-explicit-any */
import 'date-fns';
import React from 'react';
import { Controller, Control } from 'react-hook-form';

import { Box, makeStyles, TextFieldProps, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Error } from '@material-ui/icons';
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '6px',
    borderColor: '#FFA621',
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      boxShadow: '0px 3px 0px #FFA621',
    },
  },
}));

type Props = TextFieldProps & {
  control: Control<Record<string, any>>;
  name: string;
  label?: string;
  labelTime?: string;
  error?: any;
  style?: React.CSSProperties;
  variant?: 'outlined' | 'filled' | 'standard';
  size?: 'medium' | 'small';
  disabled?: boolean;
  required?: boolean;
  disableFuture?: boolean;
};

export const DatePickerHook: React.FC<Props> = ({
  control,
  name,
  label,
  labelTime,
  error,
  style,
  size,
  variant = 'outlined',
  disabled = false,
  required = false,
  disableFuture = false,
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ marginBottom: 15, ...style }}
    >
      <Grid container justifyContent="flex-start">
        <Controller
          name={name}
          control={control}
          render={({ onChange, value }) => (
            <>
              <KeyboardDatePicker
                className={classes.root}
                inputVariant={variant}
                id={`${label}-date-picker-dialog`}
                label={label}
                format="dd/MM/yyyy"
                value={value || undefined}
                onChange={onChange}
                error={!!error[name]?.message}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                disableFuture={disableFuture}
                disabled={disabled}
                required={required}
                size={size || 'medium'}
                style={{ flex: 1, background: '#fff' }}
              />
              {labelTime && (
                <KeyboardTimePicker
                  className={classes.root}
                  inputVariant={variant}
                  id={`${labelTime}-time-picker`}
                  label={labelTime || 'Hora'}
                  value={value}
                  onChange={onChange}
                  error={!!error[name]?.message}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  disabled={disabled}
                  size={size || 'medium'}
                  style={{ flex: 1, marginLeft: 10, background: '#fff' }}
                />
              )}
            </>
          )}
        />
      </Grid>

      {name && error[name]?.message && (
        <Typography
          variant="subtitle2"
          color="error"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Error color="error" style={{ width: 18, marginRight: 4 }} />
          {error[name]?.message}
        </Typography>
      )}
    </Box>
  );
};
