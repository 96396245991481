import React, { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@material-ui/core';
import { KeyboardArrowLeftOutlined } from '@material-ui/icons';
import axios from 'axios';
import * as yup from 'yup';

import { CustomButton } from '../../../components/CustomButton';
import InputHook from '../../../components/InputHook';
import { LoadingBee } from '../../../components/LoadingBee';
import { useAuth } from '../../../hooks/auth';
import { useCustomSnackbar } from '../../../hooks/snackbar';
import { IFormInput } from './types';

const schema = yup.object().shape({
  name: yup.string().required('O nome é obrigatório'),
  email: yup.string().required('O e-mail é obrigatório'),
  password: yup.string().required('A senha é obrigatória'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Confirmação incorreta'),
});

const Register: FC = () => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, errors } = useForm<IFormInput>({
    resolver: yupResolver(schema),
  });

  const { signIn } = useAuth();
  const { addSnackbar } = useCustomSnackbar();

  const onSubmit = useCallback(
    async ({ name, email, password, password_confirmation }: IFormInput) => {
      setLoading(true);

      axios
        .post('/register', {
          name,
          email,
          password,
          password_confirmation,
        })
        .then(() => {
          signIn({ email, password });
        })
        .catch((err) => {
          setLoading(false);

          addSnackbar({
            title:
              err.response?.data?.message ||
              'Ocorreu um erro ao se cadastrar, tente novamente.',
            variant: 'error',
          });
        });
    },
    [signIn, addSnackbar],
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        gridGap="10px"
        mb={4}
        width="100%"
      >
        <Link to="/">
          <Typography color="secondary" component="div">
            <Box
              fontSize="14px"
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              width="100%"
              color="primary.main"
            >
              <KeyboardArrowLeftOutlined />
              Voltar
            </Box>
          </Typography>
        </Link>
      </Box>

      <Box
        component="form"
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        width="100%"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography component="div">
          <Box fontWeight="bold" fontSize="18px" color="text.secondary" mb={2}>
            Como podemos chamar você?
          </Box>
        </Typography>
        <InputHook
          name="name"
          type="text"
          placeholder="Nome"
          variant="outlined"
          control={control}
          error={errors}
          style={{ marginBottom: 16, width: '100%' }}
          styleInput={{
            borderColor: '#E6E6F0',
            textAlign: 'center',
          }}
          disabled={!!loading}
          size="medium"
          required
        />

        <Typography component="div">
          <Box fontWeight="bold" fontSize="18px" color="text.secondary" mb={2}>
            Qual seu melhor e-mail?
          </Box>
        </Typography>

        <InputHook
          name="email"
          type="text"
          placeholder="E-mail"
          variant="outlined"
          control={control}
          error={errors}
          style={{ marginBottom: 16, width: '100%' }}
          styleInput={{
            borderColor: '#E6E6F0',
            textAlign: 'center',
          }}
          disabled={!!loading}
          size="medium"
          required
        />

        <Typography component="div">
          <Box fontWeight="bold" fontSize="18px" color="text.secondary" mb={2}>
            Qual senha gostaria de usar?
          </Box>
        </Typography>
        <InputHook
          name="password"
          type="password"
          placeholder="Senha"
          variant="outlined"
          control={control}
          error={errors}
          style={{ marginBottom: 0, width: '100%' }}
          styleInput={{ borderColor: '#E6E6F0', textAlign: 'center' }}
          disabled={!!loading}
          size="medium"
          required
        />

        <InputHook
          name="password_confirmation"
          type="password"
          placeholder="Confirme a senha"
          variant="outlined"
          control={control}
          error={errors}
          style={{ marginBottom: 26, width: '100%' }}
          styleInput={{ borderColor: '#E6E6F0', textAlign: 'center' }}
          disabled={!!loading}
          size="medium"
          required
        />

        <Box display="flex" justifyContent="center" mb={4} width="100%">
          <CustomButton
            type="submit"
            disabled={loading}
            style={{ width: '100%', height: 56 }}
          >
            {loading ? <LoadingBee /> : 'Criar Conta'}
          </CustomButton>
        </Box>
      </Box>
    </>
  );
};

export default Register;
