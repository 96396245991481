import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Box,
  Divider,
  Drawer,
  useMediaQuery,
  Typography,
  Button,
} from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import RepeatOutlinedIcon from '@material-ui/icons/RepeatOutlined';
import axios from 'axios';

import logoImg from '../../assets/logo.png';
import bgHeaderImg from '../../assets/manganga-bg-header.jpg';
import bgImg from '../../assets/manganga-bg.png';
import { Footer } from '../../components/Footer';
import { MenuItems } from '../../components/Menu/MenuItems';
import { MenuItemSignOut } from '../../components/Menu/MenuItemSignOut';
import { MenuMobile } from '../../components/Menu/MenuMobile';
import { MenuDialogMeliponario } from '../../components/MenuDialogMeliponario';
import { NavBar } from '../../components/NavBar';
import config from '../../config/environmentConfig';
import menu from '../../config/menuConfig';
import { useAuth } from '../../hooks/auth';
import { useMeliponario } from '../../hooks/meliponario';
import { useCustomSnackbar } from '../../hooks/snackbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    drawerPaper: {
      background: theme.palette.info.main,
      backgroundImage: `linear-gradient(#f8f0d7f0, #f8f0d7f0), url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'left',
      backgroundRepeat: 'no-repeat',
      position: 'absolute',
      borderColor: '#fbf3db',
      top: 0,
      left: 0,
      whiteSpace: 'nowrap',
      width: '240px',
      height: '100vh',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('sm')]: {
        overflowX: 'hidden',
        display: 'none',
        width: 0,
      },
    },
    toolbarImage: {
      height: '100%',
      maxHeight: '55px',
      marginLeft: 'auto',
      marginRight: 'auto',
      borderRadius: 4,
      padding: '4px 10px',
      backgroundColor: '#ffe498b8',
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
      backgroundImage: `url(${bgHeaderImg}), linear-gradient(#f39c12, #d07609)`,
      backgroundSize: '100% auto',
      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'cover',
      },
    },
    main: {
      margin: 0,
      background: theme.palette.info.main,
      backgroundImage: `linear-gradient(#f8f0d7f0, #f8f0d7f0), url(${bgImg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'left',
      backgroundRepeat: 'no-repeat',
      padding: theme.spacing(2),
      height: `calc(100vh - 56px)`,
      overflow: 'auto',
      [theme.breakpoints.up('sm')]: {
        height: `calc(100vh - 64px)`,
        width: '100%',
        maxWidth: 'calc(100% - 240px)',
        marginLeft: '240px',
      },
    },
    contentPage: {
      borderRadius: theme.spacing(1),
      background: '#FBF3DB',
      boxShadow: '0px 3px 6px #0000004D',
      minHeight: 'calc(100vh - 88px)',
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      marginBottom: theme.spacing(4),
    },
    title: {
      display: 'block',
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
    },
    buttonSelectMeliponario: {
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
    },
    menuOptions: {
      '& .MuiPopover-paper': {
        background: '#FBF3DB',
      },
    },
  }),
);

const Default: FC = ({ children }) => {
  const { addSnackbar } = useCustomSnackbar();
  const matches = useMediaQuery('(min-width:600px)');
  const { signOut, user } = useAuth();
  const { meliponarios, onSelectMeliponario, selectedMeliponario } = useMeliponario(); // eslint-disable-line
  const appConfig = useMemo(() => {
    return {
      version: config.getEnv('REACT_APP_VERSION'),
      appName: config.getEnv('REACT_APP_NAME'),
      appFullName: config.getEnv('REACT_APP_FULL_NAME'),
    };
  }, []);

  const { location, push } = useHistory();
  const menuSelected = useMemo(() => {
    return menu.getSelected(location.pathname);
  }, [location.pathname]);

  const primaryMenus = menu.getPrimaryMenus();
  const secondMenus = menu.getSecondMenus();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null); // eslint-disable-line

  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenuMeliponario, setOpenMenuMeliponario] = useState(false);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  useEffect(() => {
    setOpen(matches);
  }, [matches]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    setOpenMenu(false);
  };

  const handleMenuSignOut = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    setOpenMenu(false);
    signOut();
  };

  const handleMenuPush = (router: string) => {
    handleMenuClose();
    if (router === '/messages') {
      setNotify(false);
      push(router);
      axios.post('/viewed-notifications');
    }
    if (router === '/technical-supports') {
      push(router);
    }
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const [notify, setNotify] = useState(false);

  useEffect(() => {
    axios.get('/has-notifications').then((response) => {
      if (response.data.data.new_notification) {
        setNotify(true);
      }
    });
  }, []);

  useEffect(() => {
    axios.get('/technical-supports').then((response) => {
      const suportes = response.data.data.data.map(
        (suporteNote: { updated_at: String }) => ({
          updated_at: suporteNote.updated_at,
        }),
      );
      const firstElement = suportes.shift();
      const secondElement = suportes[0];
    });
  }, []);
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.menuOptions}
    >
      <MenuItem onClick={() => handleMenuPush('/profile')}>Perfil</MenuItem>
      <MenuItem onClick={handleMenuSignOut}>Sair</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className={classes.menuOptions}
    >
      <MenuItem onClick={() => handleMenuPush('/technical-supports')}>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent="" color="error" variant="dot">
            <AssignmentIcon />
          </Badge>
        </IconButton>
        <p>Suporte</p>
      </MenuItem>
      <MenuItem onClick={() => handleMenuPush('/messages')}>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          {notify ? (
            <Badge badgeContent="" color="error" variant="dot">
              <MailIcon />
            </Badge>
          ) : (
            <MailIcon />
          )}
        </IconButton>
        <p>Menssages</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Olá, {user?.name.split(' ')[0]}</p>
      </MenuItem>
    </Menu>
  );

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const handleClickOpenMenuMeliponario = useCallback(() => {
    setOpenMenuMeliponario(true);
  }, []);

  const handleSelectMeliponario = useCallback(
    (values: { id: number; name: string } | undefined) => {
      setOpenMenuMeliponario(false);

      if (!values || !selectedMeliponario?.id) return;

      if (values.id === selectedMeliponario.id) return;

      onSelectMeliponario({
        id: values.id,
        name: values.name,
      });
    },
    [selectedMeliponario, onSelectMeliponario],
  );

  return (
    <Box className={classes.grow}>
      <NavBar
        onOpenMenu={() => setOpenMenu(true)}
        menuId={menuId}
        mobileMenuId={mobileMenuId}
        onMobileMenuOpen={handleMobileMenuOpen}
        onProfileMenuOpen={handleProfileMenuOpen}
      >
        {menuSelected?.url === '/home' ? (
          <Button
            variant="text"
            className={classes.buttonSelectMeliponario}
            onClick={handleClickOpenMenuMeliponario}
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              textTransform: 'inherit',
              margin: '0 auto',
              color: '#8A2B00',
            }}
            disabled={!selectedMeliponario?.name}
          >
            {selectedMeliponario?.name ? (
              <>
                <Typography className={classes.title} variant="h6" noWrap>
                  {selectedMeliponario?.name}
                </Typography>
                <RepeatOutlinedIcon style={{ marginLeft: 10 }} />
              </>
            ) : (
              <Typography className={classes.title} variant="h6" noWrap>
                Mangangá
              </Typography>
            )}
          </Button>
        ) : (
          <Typography className={classes.title} variant="h6" noWrap>
            {menuSelected?.title}
          </Typography>
        )}
      </NavBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <img src={logoImg} alt="Logo" className={classes.toolbarImage} />
        </div>
        <Divider />
        {/* Menu Desktop */}
        <MenuItems data={primaryMenus} divider onClick={handleMenuClose} />
        <MenuItems data={secondMenus} divider onClick={handleMenuClose} />
        <MenuItemSignOut signOut={handleSignOut} />

        <Footer mt={5} version={appConfig.version} />
      </Drawer>

      <Box
        component="main"
        flexGrow={1}
        overflow="auto"
        className={classes.main}
      >
        <Container maxWidth="lg" className={classes.contentPage}>
          <>{children}</>
        </Container>
      </Box>

      <MenuMobile open={openMenu} onClose={() => setOpenMenu(false)}>
        <MenuItems data={primaryMenus} divider onClick={handleMenuClose} />
        <MenuItems data={secondMenus} divider onClick={handleMenuClose} />
        <MenuItemSignOut signOut={handleSignOut} />

        <Footer mt={5} version={appConfig.version} />
      </MenuMobile>

      {renderMobileMenu}
      {renderMenu}
      <MenuDialogMeliponario
        open={openMenuMeliponario}
        data={meliponarios}
        onClose={(value) => handleSelectMeliponario(value)}
      />
    </Box>
  );
};

export default Default;
