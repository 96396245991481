import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import axios from 'axios';

import { useAuth } from './auth';

interface IMeliponario {
  id: number;
  name: string;
  user_id: number;
}

interface ResponseData {
  id: number;
  name: string;
  user_id: number;
}

interface MeliponarioContextData {
  meliponarios: ResponseData[];
  selectedMeliponario?: IMeliponario | undefined;
  onSelectMeliponario: (data: { id: number; name: string }) => void;
  refreshMeliponarios: () => void;
}

const MeliponarioContext = createContext<MeliponarioContextData>(
  {} as MeliponarioContextData,
);

const MeliponarioProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const [meliponarios, setMeliponarios] = useState<ResponseData[]>([]);
  const [selectedMeliponario, setSelectedMeliponario] = useState<
    IMeliponario | undefined
  >(() => {
    const meliponario = localStorage.getItem('@app:meliponario');

    if (!meliponario) {
      return undefined;
    }

    const parseMeliponario = JSON.parse(meliponario);

    if (
      !parseMeliponario?.user_id ||
      Number(parseMeliponario.user_id) !== Number(user?.id)
    ) {
      return undefined;
    }

    return JSON.parse(meliponario);
  });

  useEffect(() => {
    if (user?.id) {
      axios
        .get<{ data: ResponseData[] }>('/meliponarios/all')
        .then((response) => {
          setMeliponarios(response.data?.data);

          setSelectedMeliponario((state) => {
            if (
              (state?.user_id && Number(user?.id) !== state.user_id) ||
              !state?.id
            ) {
              localStorage.setItem(
                '@app:meliponario',
                JSON.stringify(response.data?.data[0] || ''),
              );
              return response.data?.data[0];
            }
            return state;
          });
        })
        .catch((err) => {
          console.log('error', err);
        });
    }
  }, [user?.id]);

  const onSelectMeliponario = useCallback(
    ({ id, name }: { id: number; name: string }) => {
      setSelectedMeliponario({ id, name, user_id: Number(user.id) });
      localStorage.setItem(
        '@app:meliponario',
        JSON.stringify({ id, name, user_id: Number(user.id) }),
      );
    },
    [user?.id],
  );

  const refreshMeliponarios = useCallback(() => {
    axios
      .get<{ data: ResponseData[] }>('/meliponarios/all')
      .then((response) => {
        setMeliponarios(response.data?.data);
        setSelectedMeliponario((state) => {
          if (!state?.id) {
            localStorage.setItem(
              '@app:meliponario',
              JSON.stringify(response.data?.data[0] || ''),
            );
            return response.data?.data[0];
          }
          return state;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <MeliponarioContext.Provider
      value={{
        meliponarios,
        onSelectMeliponario,
        selectedMeliponario,
        refreshMeliponarios,
      }}
    >
      {children}
    </MeliponarioContext.Provider>
  );
};

function useMeliponario(): MeliponarioContextData {
  const context = useContext(MeliponarioContext);

  if (!context) {
    throw new Error(
      'useMeliponario must be used within an MeliponarioProvider',
    );
  }

  return context;
}

export { MeliponarioProvider, useMeliponario };
